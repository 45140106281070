import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities, EntityMap } from 'app/entity';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { StandardApi } from 'core/module';

/**
 * Standard File decorator urls generator based on entity path
 */
export const getFilesUrls = (baseUrl: string) => ({
  /**
   * List entities available to assign to file
   */
  entities: getResourcesUrl(`${baseUrl}`),
  /**
   * Entity files service
   * @param {ID} entityId
   * @returns {string} entity list url
   */
  files: (entityId: number) => getResourcesUrl(`${baseUrl}/${entityId}/files`),
  /**
   * File detail
   * @param {ID} entityId
   * @param {ID} fileId
   * @returns {string} file url
   */
  file: (entityId: number, fileId: number) =>
    getResourcesUrl(`${baseUrl}/${entityId}/files/${fileId}`),
  /**
   * Download file
   * @param {ID} entityId
   * @param {ID} fileId
   * @returns {string} download url
   */
  download: (entityId: number, fileId: number) =>
    getResourcesUrl(`${baseUrl}/${entityId}/files/${fileId}/download`),
  /**
   * Bulk download multiple files
   * @param {ID} eventsId
   * @returns {string} bulk download
   * TODO: should not be id filter here ?
   */
  bulkDownload: (eventsId: number) => `${baseUrl}/files/bulkDownload/${eventsId}`,
  /**
   * Bulk upload multiple files
   * @param {ID} eventsId
   * @returns {string} bulk download
   */
  bulkUpload: `${baseUrl}/files/bulk`,
  /**
   * Unrestricted Roles
   * @returns {string} unrestricted roles url
   */
  unrestrictedRoles: `${baseUrl}/files/unrestrictedRoles`,
});

/**
 * List of entities which supports file upload
 */
export const entityModel = {
  company: 'Companies',
  project: 'Projects',
  contact: 'Contacts',
  invoice: 'Invoices',
} as const;

type Map = typeof entityModel;
export type FileEntityKey = keyof Map;
export type FileEntityName = Map[FileEntityKey];

const modelEntity = (Object.keys(entityModel) as Array<keyof typeof entityModel>).reduce(
  (acc, k) => ({ ...acc, [entityModel[k]]: k }),
  {} as Record<FileEntityName, FileEntityKey>
);

/**
 * Find file entity name from key
 * @param {string} key
 * @returns {FileEntityName}
 */
export function getFileEntityName(key: FileEntityKey) {
  return entityModel[key];
}

/**
 * Get entity key from entity model name
 * @param {string} name
 * @returns {FileEntityKey}
 */
export function getFileEntityKey(name: FileEntityName) {
  return modelEntity[name];
}

export const useFileEntityLookupByName = (name: FileEntityName) => {
  const { t } = useTranslation();
  const entity = getFileEntityKey(name);

  return useMemo(
    () => ({
      label: entities[entity].name(t),
      urls: getFilesUrls(entities[entity].api('').list),
      entityName: entityModel[entity],
    }),
    [entity, t]
  );
};

export const useGetFileEntityLookupByName = () => {
  const { t } = useTranslation();
  return useCallback(
    (name: FileEntityName) => {
      const entity = getFileEntityKey(name);
      return {
        label: entities[entity].name(t),
        urls: getFilesUrls(entities[entity].api('').list),
        entityName: entityModel[entity],
      };
    },
    [t]
  );
};

export const useFileEntityLookup = (
  entity: FileEntityKey,
  entityContext?: Parameters<EntityMap[FileEntityKey]['api']>[0]
) => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      label: entities[entity].name(t),
      urls: getFilesUrls(entities[entity].api(entityContext).list),
      entityName: entityModel[entity],
    }),
    [entity, entityContext, t]
  );
};

export const useFileEntityLookups = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      (Object.keys(entityModel) as FileEntityKey[]).reduce(
        (acc, entity) => ({
          ...acc,

          [entity]: {
            label: entities[entity].name(t),
            urls: getFilesUrls(entities[entity].api('').list),
            entityName: entityModel[entity],
          },
        }),
        {} as Record<FileEntityKey, { label: string; urls: () => StandardApi; entityName: string }>
      ),
    [t]
  );
};
