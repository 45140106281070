import { entities } from 'app/entity';
import { definition, EntityMeta, getStandardApi, getStandardUrls, T } from 'core/module';

import { TimeSheetDetail, TimeSheetGrid, TimeSheetCreate } from './models/TimeSheet';

export const timeSheetEntities = {
  timesheet: {
    ...definition<TimeSheetGrid, TimeSheetDetail, TimeSheetCreate>('timesheets', {
      name: (t: T) => t('Timesheet'),
      listName: (t: T) => t('Timesheets'),
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('timesheets'),
      createInvoice: (projectsId: number, timesheetsId: number) =>
        `${entities.invoice.urls().create}/detail?projectsId=${projectsId}&timesheetsId=${timesheetsId}`,
    }),
    api: (_?: string) => ({
      ...getStandardApi('timesheets'),
    }),
  },
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof timeSheetEntities, EntityMeta> = timeSheetEntities;

export type K = keyof typeof timeSheetEntities;
