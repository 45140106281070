import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePermission, { useGetPermission } from 'core/hooks/usePermission';
import { coreEntities } from 'core/module';
import { financeEntities } from 'finances/module';
import { forecastEntities } from 'forecasts/module';
import { listEntities } from 'lists/module';
import { personnelEntities } from 'personnel/module';
import { projectsEntities } from 'projects/module';
import { salesEntities } from 'sales/module';
import { timeSheetEntities } from 'timesheets/module';

export const entities = {
  ...salesEntities,
  ...coreEntities,
  ...personnelEntities,
  ...projectsEntities,
  ...timeSheetEntities,
  ...forecastEntities,
  ...financeEntities,
  ...listEntities,
} as const;

export type EntityKey = keyof typeof entities;

export type FileEntityKey = 'company' | 'project' | 'contact' | 'invoice';

export type EntityMap = typeof entities;

type FilterConditionally<Source, Condition> = Pick<
  Source,
  { [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

export type NoContextEntityKey = keyof FilterConditionally<EntityMap, { urls: () => any }>;

export const useEntityName = (entity: EntityKey) => {
  const { t } = useTranslation();
  return useMemo(() => entities[entity].name(t), [t, entity]);
};

export const getEntityPermission = (entity: EntityKey): string => entities[entity].permission;

export const useEntityPermission = (entity: EntityKey, action: string): boolean => {
  return usePermission(`${getEntityPermission(entity)}:${action}`);
};

export const useGetEntityPermission = () => {
  const get = useGetPermission();

  return useCallback(
    (entity: EntityKey, action: string) => get(`${getEntityPermission(entity)}:${action}`),
    [get]
  );
};
